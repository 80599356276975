<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import QRCode from "qrcode";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      active: 0,
      adminqrcode: "",

      sn: "",
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.nfcreader.bind");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.nfcreader.text"),
        href: "/clouds/nfcreader",
      },
      {
        text: this.$t("menuitems.clouds.nfcreader.bind"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
  },
  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
      if (this.active == 2) {
        this.getadmincode();
      }
    },
    manualbind() {
      var that = this;
      if (that.sn.length === 0) {
        that.$message({
          message: $t("menuitems.clouds.nfcreader.snrequire"),
          type: "warning",
        });
      } else {
        that.loading = true;
        that.$axios
          .post(
            that.apiuri,
            {
              action: "cardreaderbind",
              sn: that.sn,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            that.loading = false;
            if (response.data.status == 200) {
              that.$message({
                message: that.$t("menuitems.clouds.nfcreader.bindsuccess"),
                type: "success",
              });
              that.active++;
            } else {
              that.$message.error(
                that.$t("menuitems.clouds.nfcreader.bindfail")
              );
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getadmincode() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getadmincode",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.makeqrcode(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async makeqrcode(data) {
      const canvas = this.$refs.canvas;
      const options = { width: 384, height: 384 };
      const dataUrl = await QRCode.toDataURL(data, options);
      canvas.width = options.width;
      canvas.height = options.height;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, options.width, options.height);
        canvas.toBlob((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            this.qrCode = reader.result;
          };
          reader.readAsDataURL(blob);
        });
      };
      this.adminqrcode = dataUrl;
      return dataUrl;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <canvas style="display: none" ref="canvas"></canvas>
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <el-steps
              :active="active"
              finish-status="success"
              style="margin-top: 20px"
              align-center
            >
              <el-step
                :title="$t('menuitems.clouds.nfcreader.step', { number: 1 })"
                :description="$t('menuitems.clouds.nfcreader.activedevice')"
              ></el-step>
              <el-step
                :title="$t('menuitems.clouds.nfcreader.step', { number: 2 })"
                :description="$t('menuitems.clouds.nfcreader.bindtocloud')"
              ></el-step>
              <el-step
                :title="$t('menuitems.clouds.nfcreader.step', { number: 3 })"
                :description="$t('menuitems.clouds.nfcreader.admintocloud')"
              ></el-step>
              <el-step
                :title="$t('menuitems.clouds.nfcreader.step', { number: 4 })"
                :description="$t('menuitems.clouds.nfcreader.tester')"
              ></el-step>
            </el-steps>
            <el-card class="box-card" v-if="active == 0">
              <p>1. {{ $t("menuitems.clouds.nfcreader.setps.one") }}</p>
              <p>2. {{ $t("menuitems.clouds.nfcreader.setps.two") }}</p>
              <p>3. {{ $t("menuitems.clouds.nfcreader.setps.three") }}</p>
            </el-card>
            <div class="row" v-if="active == 1">
              <div class="col-12">
                <el-card>
                  <div style="text-align: center">
                    <img
                      src="https://i2.aic.la/220706/87e95866923d0.jpg"
                      class="image"
                    />
                    <div style="padding: 14px">
                      <span>{{
                        $t("menuitems.clouds.nfcreader.setps.devicemrgapp")
                      }}</span>
                    </div>
                  </div>
                </el-card>
              </div>
            </div>
            <div class="row" v-if="active == 2" v-loading="loading">
              <div class="col-6">
                <el-card :body-style="{ padding: '10px' }">
                  <div slot="header" class="clearfix">
                    <span>{{ $t("menuitems.clouds.nfcreader.autobind") }}</span>
                  </div>
                  <div style="text-align: center">
                    <img :src="adminqrcode" class="image" />
                    <div style="padding: 14px">
                      <span>{{
                        $t("menuitems.clouds.nfcreader.setps.four")
                      }}</span>
                    </div>
                  </div>
                </el-card>
              </div>
              <div class="col-6">
                <el-card :body-style="{ padding: '10px' }">
                  <div slot="header" class="clearfix">
                    <span>{{
                      $t("menuitems.clouds.nfcreader.manualbind")
                    }}</span>
                  </div>
                  <el-form label-width="100px">
                    <el-form-item
                      :rules="[
                        {
                          require: true,
                          message: $t('menuitems.clouds.nfcreader.snrequire'),
                        },
                      ]"
                      :label="$t('menuitems.clouds.nfcreader.devicesn')"
                    >
                      <el-input v-model="sn"></el-input>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="manualbind()">{{
                        $t("setting.account.save")
                      }}</el-button>
                    </el-form-item>
                  </el-form>
                </el-card>
              </div>
            </div>
            <el-card class="box-card" v-if="active == 3">
              <el-result
                icon="success"
                :title="$t('menuitems.clouds.nfcreader.bindsuccess')"
                :subTitle="$t('menuitems.clouds.nfcreader.bindsuccessmessage')"
              >
                <template slot="extra">
                  <router-link to="/clouds/nfcreader">
                    <el-button style="margin-top: 12px" @click="next">{{
                      $t("menuitems.clouds.nfcreader.finish")
                    }}</el-button>
                  </router-link>
                </template>
              </el-result></el-card
            >
            <div class="col-12" style="text-align: center">
              <el-button
                style="margin-top: 12px"
                v-if="active != 3"
                @click="next"
                >{{ $t("menuitems.clouds.nfcreader.next") }}</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>